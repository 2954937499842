import React from 'react';
import {Button, Input, Modal, Table, Tag, message} from 'antd';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('admin-search-product-modal', null, {autoDelete: false});

export function showAdminSearchProductModal(data) {
  getOutlet('admin-search-product-modal').update(data);
}

const fetchRecords = async (
  keyword,
  columns,
  paging = {offset: 0, limit: 4},
) => {
  try {
    AppActions.setLoading(true);

    const queries = {
      is_old: {$ne: true},
      archived: {$ne: true},
      $or: columns.map((column) => ({
        [column.key]: {$regex: keyword},
      })),
    };

    return Jstorage.fetchDocuments('product', queries, null, paging);
  } catch (err) {
    message.error('發生錯誤。');
  } finally {
    AppActions.setLoading(false);
  }
};

export default function AdminSearchProductModal(props) {
  const [data, setData] = useOutlet('admin-search-product-modal');
  const [keyword, setKeyword] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  // const [_selectedRows, _setSelectedRows] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 4,
  });

  const columns = [
    {
      key: 'serial_number',
      title: '編號',
      dataIndex: 'serial_number',
      width: 100,
      render: (_, item) => <div>{item.serial_number || '---'}</div>,
    },
    {
      key: 'name',
      title: '名稱',
      dataIndex: 'name',
      width: 300,
      render: (_, item) => {
        const isSession =
          item.session &&
          Object.keys(item.session).length > 0 &&
          item.session.date;

        return (
          <div>
            <div>{item.name}</div>
            {isSession && (
              <div>
                {item.session.date}
                {item.session.start_time && ` ${item.session.start_time}-`}
                {item.session.end_time && `${item.session.end_time}`}
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: 'internal_label',
      title: '公會內部分類',
      dataIndex: 'internal_label',
    },
    {
      key: 'labels',
      title: '官網/App分類',
      dataIndex: 'labels',
      render: (_, item) => {
        return item.labels?.map((l) => <Tag>{l}</Tag>);
      },
    },
    {
      key: 'price',
      title: '價錢',
      dataIndex: 'price',
      width: 100,
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item.id);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(null);
    setRecords(null);
    // _setSelectedRows([]);
    setData(null);
  }

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <Wrapper>
        <Input.Search
          style={{marginBottom: 10}}
          placeholder="請輸入關鍵字"
          allowClear
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={async () => {
            let {total, results} = await fetchRecords(keyword, columns, {
              offset: 0,
              limit: pagination.pageSize,
            });
            setRecords(results);
            setPagination({...pagination, total});
          }}
        />

        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 400}}
          pagination={{
            ...pagination,
            size: 'small',
            showTotal: (total) => `共 ${total} 筆`,
            onChange: async (page, pageSize) => {
              let {total, results} = await fetchRecords(keyword, columns, {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
              setRecords(results);
              setPagination({...pagination, total});
            },
          }}
          columns={columns}
          dataSource={records}
        />
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled(ModalCustomStyle)``;
