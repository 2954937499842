import {getOutlet} from 'reconnect.js';
import Config from '../../../data.json';
import {getStaffValue} from '../../Utils/StaffUtil';

const UserOutlet = getOutlet('user');

const ACCESS_LIST = [
  //stg
  //prod
  '63aea7e98ae07b4d5d89cc6f', //Admin
  '651aaf9174161bc40ce7b087', //吳淑瑜
  '65294b8168a16d9d16983c74', //王冠智
];

export function getAdminRoutes() {
  const config = getOutlet('config');

  return [
    {name: '行事曆', path: '/admin/'},
    {
      name: '會員管理',
      path: '__group-user',
      subroutes: [
        {name: '會員全覽', path: '/admin/users/'},
        {name: '會員查詢', path: '/admin/users/export'},
        {name: '會員入會審核', path: '/admin/users-join-review/'},
        {name: '會員退會紀錄', path: '/admin/users-withdraw-record/'},
        {name: '會員停止紀錄', path: '/admin/users-stop-record'},
        {name: '會員參與紀錄', path: '/admin/users-attend-record/'},
        {name: '事務所全覽', path: '/admin/companies/'},
        {name: '團體管理', path: '/admin/committees/'},
        {name: '團體集合管理', path: '/admin/committee-summaries/'},
        {name: '傳真管理', path: '/admin/fax/export'},
        {name: '報表管理', path: '/admin/user-reports/'},
      ],
    },
    {
      name: '繳費管理',
      path: '__group_order',
      subroutes: [
        {name: '訂單查詢', path: '/admin/orders/export'},
        {name: '常年會費：會員', path: '/admin/user-billings/'},
        {name: '跨區會費：快捷報名', path: '/admin/user-checkout-cross/'},
        {name: '會費明細', path: '/admin/orders-fee/'},
        {name: '課程繳費明細', path: '/admin/orders-course/'},
        {name: '活動繳費明細', path: '/admin/orders-activity/'},
        {name: '報名取消紀錄', path: '/admin/orders/return/'},
        {name: '會費退款紀錄', path: '/admin/orders-refund/'},
        // {name: '補收款管理', path: '/admin/recharge-record/'},
        {name: '常年會費：年度開徵', path: '/admin/annual-fee-create'},
        {name: '常年會費：訊息通知', path: '/admin/annual-fee-notify'},
        {name: '常年會費：訊息紀錄', path: '/admin/fee-notify-backup/'},
        {name: '常年會費：事務所', path: '/admin/company-billings/'},
        {name: '會費變動紀錄', path: '/admin/fee-history/'},
        {name: '給付管理', path: '/admin/pay-billings/'},
        {name: '報表管理', path: '/admin/order-reports/'},
      ],
    },
    {
      name: '活動/課程管理',
      path: '__group-product',
      subroutes: [
        {name: '活動/課程快捷報名', path: '/admin/user-checkout/'},
        {name: '活動/課程全覽', path: '/admin/products/'},
        {name: '報名人數管理', path: '/admin/stocks/'},
        {name: '報名人數管理紀錄', path: '/admin/stock-records/'},
        {name: '活動/課程簽到表', path: '/admin/sign_forms/'},
        {name: '活動/課程封存', path: '/admin/archived_products/'},
        {name: '北律雲-影片管理', path: '/admin/videos/'},
        {name: '北律雲-直播串流管理', path: '/admin/live_streams/'},
        {name: '會員大會', path: '/admin/parties/'},
        {name: '國際交流', path: '/admin/international_exchange/'},
        {name: '報表管理', path: '/admin/product-reports/'},
        // {name: '多語系(Beta)', path: '/admin/i18n-products/'},
      ],
    },
    {
      name: '文書管理',
      path: '__group-paperwork',
      subroutes: [
        {name: '收文管理', path: '/admin/receives/'},
        {name: '發文管理', path: '/admin/posts/'},
        {name: '簽核管理', path: '/admin/reviews/'},
        {name: '執行管理', path: '/admin/executions/'},
        {name: '訊息傳送', path: '/admin/internal_messages/'},
        {name: '報表管理', path: '/admin/paperwork-reports/'},
        {name: '參數設定', path: '/admin/form-counter/'},
        {name: '收文內容管理', path: '/admin/content-receives'},
        {name: '發文內容管理', path: '/admin/content-posts'},
      ],
    },
    {
      name: '法務管理',
      path: '__group-law',
      subroutes: [
        {name: '倫理風紀', path: '/admin/morales'},
        {name: '報表管理', path: '/admin/law-reports'},
      ],
    },
    {
      name: '官網/App管理',
      path: '__group-client',
      subroutes: [
        {name: '會議管理', path: '/admin/meetings/'},
        {name: '會議封存', path: '/admin/archived_meetings/'},
        {name: '新聞稿/重大訊息刊登', path: '/admin/news/'},
        {name: '問卷全覽', path: '/admin/surveys/'},
        {name: '問卷名單管理', path: '/admin/survey_group/'},
        {name: '問卷調查結果', path: '/admin/survey_resps/'},
        {name: '事務所變更申請審核', path: '/admin/company-reviews/'},
        {name: '求才刊登申請審核', path: '/admin/job-reviews/'},
        {name: '辦公室刊登申請審核', path: '/admin/room-reviews/'},
        {name: '館藏管理', path: '/admin/library/'},
        {name: '大學期刊管理', path: '/admin/university-serials/'},
        {name: '律師法學期刊', path: '/admin/legal-journals/'},
        {name: '新書通報', path: '/admin/books/'},
        {name: '會刊', path: '/admin/magazines/'},
        {name: '公會出版品', path: '/admin/publications/'},
        {name: '律師雜誌', path: '/admin/periodcals/'},
        // {name: '下載管理', path: '/admin/downloads/'},
        {name: '福利搶鮮報管理', path: '/admin/welfares/'},
        {name: '文章管理', path: '/admin/articles/'},
        {name: '頁面建置', path: '/admin/pages/'},
        Config.allowGithubRebuild
          ? {name: '前台手動更新', path: '/admin/rebuild-history/'}
          : null,
        {name: 'App橫幅廣告', path: ''},
        {name: 'App手動推播', path: '/admin/app-notification/'},
        {name: '報表管理', path: '/admin/client-reports/'},
      ],
    },
    ACCESS_LIST.includes(
      getStaffValue(
        UserOutlet.getValue().username,
        'sub',
        UserOutlet.getValue().sub,
      ),
    )
      ? {
          name: '權限管理',
          path: '__group-permission',
          subroutes: [
            {name: '工作人員全覽', path: '/admin/permission-staffs/'},
            {name: '工作人員讀寫權限', path: '/admin/permission-readwrite/'},
            {name: '報表管理', path: '/admin/permission-reports/'},
          ],
        }
      : null,

    {
      name: '上傳工具',
      path: '__group-uploader',
      subroutes: [
        {name: '圖片上傳', path: 'image-uploader'},
        {name: '檔案上傳', path: 'file-uploader'},
      ],
    },
    {name: '網站設定', path: '/admin/site/'},
    {name: '重設密碼', path: 'reset-password'},
    {name: '登出', path: 'logout'},
  ]
    .filter(
      (_) =>
        !!_ &&
        (_.path === '/admin/rebuild-history'
          ? Config.allowGithubRebuild
          : true),
    )
    .map((route) => {
      if (route.name === '優惠' && !!config.getValue()) {
        let combine_discount_index = route.subroutes.findIndex(
          (subroute) => subroute.name === '組合折扣',
        );

        route.subroutes[
          combine_discount_index
        ].path = `/admin/combine-discount/?action=detail&id=${
          config.getValue().id
        }`;
      }

      if (route.name === '官網/App管理' && !!config.getValue()) {
        let app_banner_index = route.subroutes.findIndex(
          (subroute) => subroute.name === 'App橫幅廣告',
        );

        route.subroutes[
          app_banner_index
        ].path = `/admin/app-banner/?action=detail&id=${config.getValue().id}`;
      }

      return route;
    });
}
