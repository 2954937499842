const meetingRooms = [
  'r1',
  'r2',
  'r3',
  'r4',
  'r5',
  'r6',
  'r3-r4',
  'r4-r5',
  'r3-r4-r5',
];

function getMeetingRoomLabel(value, note) {
  switch (value) {
    case 'r1':
      return '第1會議室';
    case 'r2':
      return '第2會議室';
    case 'r3':
      return '第3會議室';
    case 'r4':
      return '第4會議室';
    case 'r5':
      return '第5會議室';
    case 'r6':
      return '第6會議室';
    case 'r3-r4':
      return '第3、4會議室';
    case 'r4-r5':
      return '第4、5會議室';
    case 'r3-r4-r5':
      return '第3、4、5會議室';
    case 'other':
      return note || '';
    default:
      return '';
  }
}

function getMeetingTypeLabel(value) {
  switch (value) {
    case 'INNER':
      return '內部';
    case 'OUTER':
      return '外部';
    case 'GSM':
      return '會員大會';
    case 'BM':
      return '理事會';
    case 'SV':
      return '監事會';
    case 'MD':
      return '常務理事會議';
    case 'BOSM':
      return '理監事聯席會議';
    default:
      return value;
  }
}

function getMeetingEntruseInLabel(value) {
  return value === 'Y' ? '是' : '否';
}

function getDegreeLabel(value) {
  switch (value) {
    case 'B':
      return '大學';
    case 'M':
      return '研究所';
    case 'D':
      return '博士班';
    default:
      return '';
  }
}

function getCommitteePositionLabel(value) {
  switch (value) {
    case 'B01':
      return '主任委員';
    case 'B02':
      return '委員';
    case 'B03':
      return '副主任委員';
    case 'Z01':
      return '顧問';
    case 'Z02':
      return '會員';
    default:
      return '';
  }
}

function getFormTypeLabel(value) {
  switch (value) {
    case 'receive':
      return '收文';
    case 'post':
      return '發文';
    default:
      return '';
  }
}

function getFormStageLabel(value) {
  switch (value) {
    case 'edit':
      return '編輯';
    case 'review_1':
      return '會簽1輪';
    case 'review_2':
      return '會簽2輪';
    case 'review_3':
      return '會簽3輪';
    case 'review_4':
      return '會簽4輪';
    case 'execution':
      return '執行';
    case 'execution_direct':
      return '逕付執行';
    case 'success':
      return '完成';
    case 'abort':
      return '退簽';
    default:
      return value;
  }
}

function getFormStageLabelColor(value) {
  switch (value) {
    case 'edit':
      return 'default';
    case 'review_1':
      return 'blue';
    case 'review_2':
      return 'blue';
    case 'review_3':
      return 'blue';
    case 'review_4':
      return 'blue';
    case 'execution':
      return 'purple';
    case 'execution_direct':
      return 'purple';
    case 'success':
      return 'green';
    case 'abort':
      return 'volcano';
    default:
      return 'default';
  }
}

function getCompanyReviewStatusLabel(value) {
  switch (value) {
    case 'pending':
      return '待處理';
    case 'processing':
      return '處理中';
    case 'success':
      return '審核通過';
    case 'cancel':
      return '審核退件';
    case 'changed':
      return '已變更';
    default:
      return value;
  }
}

function getCompanyReviewStatusLabelColor(value) {
  switch (value) {
    case 'pending':
      return 'cyan';
    case 'processing':
      return 'blue';
    case 'success':
      return 'green';
    case 'cancel':
      return 'volcano';
    case 'changed':
      return 'purple';
    default:
      return 'default';
  }
}

function getAnnualFeeTemplateNameLabel(value) {
  switch (value) {
    case 'on-member-annual':
      return '開徵';
    case 'on-member-december':
      return '催繳';
    case 'on-member-march':
      return '已取得執行名義之催繳';
    default:
      return 'default';
  }
}

function getLegacyFormProgressLabel(value) {
  switch (value) {
    case 0:
      return '建檔';
    case 1:
      return '簽核';
    case 2:
      return '執行';
    case 3:
      return '結案';
    case 99:
      return '';
    default:
      return value;
  }
}

function getLegacyReceiveCategoryLabel(value) {
  switch (value) {
    case 'I001':
      return '函';
    case 'I002':
      return '書函';
    case 'I003':
      return '開會通知單';
    case 'I004':
      return '申請書';
    case 'I005':
      return '申訴書';
    case 'I006':
      return '電子郵件';
    case 'I007':
      return '存證信函';
    case 'I008':
      return '傳真文';
    case 'I009':
      return '邀請函';
    case 'I010':
      return '判決書';
    case 'I011':
      return '電話紀錄';
    case 'I012':
      return '明信片';
    case 'I013':
      return '其他';
    case 'I014':
      return '抗告狀';
    case 'I015':
      return '電話傳真函';
    case 'I016':
      return '連署聲明';
    case 'I017':
      return '委任書';
    case 'I018':
      return '民事裁定';
    case 'I019':
      return '通知書';
    case 'I020':
      return '通知書';
    case 'I021':
      return '撤回申訴書';
    case 'I022':
      return '聲明上訴狀';
    case 'I023':
      return '民事庭通知書';
    case 'I024':
      return '民事上訴理由狀';
    case 'I025':
      return '新北院輝刑空107年審易字第1186號';
    case 'I026':
      return '委任書';
    case 'I027':
      return '執行命令';
    case 'I028':
      return '邀請函';
    case 'I029':
      return '申訴狀';
    case 'I030':
      return '聲請狀';
    case 'I031':
      return '民事聲請函調資料補充理由狀';
    case 'I032':
      return '民事聲請補充理由狀';
    case 'I033':
      return '民事裁定';
    case 'I034':
      return '光碟';
    case 'I035':
      return '處分書';
    case 'I036':
      return '民事答辯狀';
    case 'I037':
      return '聲明狀';
    case 'I038':
      return '備案紀錄';
    case 'I039':
      return '報案三聯單';
    case 'I040':
      return '裁處書';
    case 'I041':
      return '臉書';
    case 'I042':
      return '通知';
    case 'I043':
      return '民事聲請續行訴訟狀';
    case 'I044':
      return '撤回狀';
    case 'I045':
      return '起訴書';
    case 'I046':
      return '傳票';
    case 'I047':
      return '決議書';
    case 'I048':
      return '證明書';
    case 'I049':
      return '電子發票';
    case 'I050':
      return '稅單(裁處書)';
    case 'I051':
      return '答辯狀';
    case 'I052':
      return '移交單';
    case 'I053':
      return '民事陳報狀';
    case 'I054':
      return '繳款書';
    case 'I055':
      return '刑事裁定';
    case 'I056':
      return '言詞辯論意旨狀';
    case 'I057':
      return '裁定';
    case 'I058':
      return '移交單';
    case 'I059':
      return 'LINE';
    case 'I060':
      return '支付命令';
    case 'I061':
      return '意旨書';
    case 'I062':
      return '電子公文';
    case 'I063':
      return '繳費通知單';
    default:
      return value;
  }
}

function getLegacyPostCategoryLabel(value) {
  switch (value) {
    case 'O001':
      return '函';
    case 'O002':
      return '書函';
    case 'O003':
      return '證明書';
    case 'O004':
      return '核准函';
    case 'O005':
      return '開會通知單';
    case 'O006':
      return '公告';
    case 'O007':
      return '其他';
    case 'O008':
      return '電子郵件';
    default:
      return value;
  }
}

function getLegacyFileClassLabel(value) {
  switch (value) {
    //course
    case 115:
      return '內容圖檔';
    case 116:
      return '內容相關檔案';
    case 117:
      return '教材圖檔';
    case 118:
      return '教材相關檔案';
    case 119:
      return '簽到紀錄';
    case 120:
      return '活動紀錄';
    //ecourse
    case 122:
      return '內容圖檔';
    case 123:
      return '內容相關檔案';
    case 124:
      return '教材檔案';
    case 125:
      return '教材相關檔案';
    case 126:
      return '活動照片';
    //activity
    case 129:
      return '內容圖檔';
    case 130:
      return '內容相關檔案';
    case 131:
      return '簽到紀錄';
    case 132:
      return '活動照片';
    //post
    case 148:
      return '電子檔(擬辦原始電子檔)';
    case 149:
      return '附件';
    case 150:
      return '簽核電子檔';
    default:
      return value;
  }
}

export {
  meetingRooms,
  getMeetingRoomLabel,
  getMeetingTypeLabel,
  getMeetingEntruseInLabel,
  getDegreeLabel,
  getCommitteePositionLabel,
  getFormTypeLabel,
  getFormStageLabel,
  getFormStageLabelColor,
  getCompanyReviewStatusLabel,
  getCompanyReviewStatusLabelColor,
  getAnnualFeeTemplateNameLabel,
  getLegacyFormProgressLabel,
  getLegacyReceiveCategoryLabel,
  getLegacyPostCategoryLabel,
  getLegacyFileClassLabel,
};
