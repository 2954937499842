import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as UserUtil from '../Utils/UserUtil';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';
import AdminSelectCompanyWidget from '../Generators/AdminResource/AdminSelectCompanyWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('AdminEditCompanyCurrentModal', null, {autoDelete: false});

export function showAdminCompanyCurrentEditModal(data) {
  getOutlet('AdminEditCompanyCurrentModal').update(data);
}

export default function AdminCompanyCurrentEditModal(props) {
  const [data, setData] = useOutlet('AdminEditCompanyCurrentModal');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {context, setData} = props;
  const {instance, values, setValues} = context;

  const rjsfProps = {
    widgets: {
      'admin-select-company-widget': AdminSelectCompanyWidget,
    },
  };

  return (
    <Wrapper>
      <h2>修改現在事務所</h2>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={values}
        schema={{
          title: '',
          type: 'object',
          required: ['company_current'],
          properties: {
            company_current: {
              type: 'string',
              title: '現在事務所',
            },
            is_create_record: {
              type: 'boolean',
              title: '創建歷史事務所紀錄',
              default: true,
            },
          },
        }}
        uiSchema={{
          company_current: {
            'ui:widget': 'admin-select-company-widget',
          },
        }}
        onSubmit={async (formData) => {
          if (!formData.company_current) {
            message.warning('現在事務所必填');
            AppActions.setLoading(false);
            return;
          } else if (formData.company_current === instance.company_current) {
            message.warning('現在事務所相同');
            AppActions.setLoading(false);
            return;
          }

          try {
            AppActions.setLoading(true);

            const nextValues = {
              company_current: formData.company_current,
              company_history: instance.company_history
                ? [...instance.company_history]
                : [],
              company_zip_code: '',
              company_city: '',
              company_detail: '',
              company_phone: '',
              company_fax: '',
            };

            if (formData.is_create_record) {
              const targetUser = await JStorage.fetchOneDocument(
                'user_profile',
                {owner: instance.owner},
              );
              const legacyCompany = targetUser.company_current
                ? await JStorage.fetchOneDocument('Company', {
                    _id: {$oid: targetUser.company_current},
                  })
                : {};

              nextValues.company_history.push({
                CONAME: legacyCompany.CONAME,
                address_zip_code:
                  targetUser.company_zip_code ||
                  legacyCompany.address_zip_code ||
                  '',
                address_city:
                  targetUser.company_city || legacyCompany.address_city || '',
                address_detail:
                  targetUser.company_detail ||
                  legacyCompany.address_detail ||
                  '',
                COPHONE1:
                  targetUser.company_phone || legacyCompany.COPHONE1 || '',
                COFAX1: targetUser.company_fax || legacyCompany.COFAX1 || '',
                UPDATE_TIME: new Date().toISOString(),
              });
            }

            const isUpdateUserTwbaData = UserUtil.isUpdateUserTwbaData({
              instance,
              formData,
            });

            await AppActionsEx.adminUpdateUserUpr({
              owner: instance.owner,
              data: {
                ...nextValues,
              },
              is_stamp: isUpdateUserTwbaData[0],
            });

            setData(null);
            message.success('修改成功');
            setTimeout(() => window.location.reload(), 1000);
          } catch (err) {
            message.error(`發生錯誤 ${err}`);
            console.warn(err);
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .container {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
